body = document.querySelector('body')
cookiesPolicy = document.querySelector('.config-cookies-policy')
const cookieConfig = {
	init: function() {
		if ( !cookieConfig.getCookie('CONSENT') && !cookieConfig.getWlParameters() ) {
			cookieConfig.insertOverlay()
			cookieConfig.insertBanner()	

			document.querySelector('.accept-btn').addEventListener('click', function () {
				cookieConfig.closeBanner()
				cookieConfig.saveConfig(true, true, true)
			})

			document.querySelector('.config-btn').addEventListener('click', function () { 
				cookieConfig.insertConfig()
				cookieConfig.openConfig()
				cookieConfig.addClass(document.querySelector('.cookie-bar'), 'config-open')
			})
		}

		if ( cookiesPolicy ) {
			cookiesPolicy.addEventListener('click', function() {
				if( !document.querySelector('.cookie-overlay') )
					cookieConfig.insertOverlay()
				else {
					cookieConfig.addClass(body, 'no-scroll')
					cookieConfig.addClass(overlay, 'active')
				}
				cookieConfig.insertConfig()
				cookieConfig.openConfig()
			})
		}
	},

	insertOverlay: function () {
		body.insertAdjacentHTML('beforeend', '<div class="cookie-overlay"></div>')
		overlay = document.querySelector('.cookie-overlay')
		cookieConfig.addClass(body, 'no-scroll')
		cookieConfig.addClass(overlay, 'active')
	},

	closeOverlay: function() {
		cookieConfig.removeClass(document.querySelector('.cookie-overlay'), 'active')
		cookieConfig.removeClass(body, 'no-scroll')
	},
	
	insertBanner: function () {
		if ( !document.querySelector('.cookie-bar') )
			body.insertAdjacentHTML('beforeend', cookieConfig.bannerTpl())
	},

	closeBanner: function () {
		cookieConfig.closeOverlay()
		cookieConfig.removeClass(document.querySelector('.cookie-bar'), 'open')
	},

	insertConfig: function () {
		if ( !document.querySelector('.cookie-config') )
			body.insertAdjacentHTML('beforeend', cookieConfig.configTpl())
	},

	checkCookies: function(config) {
		analytic = config.querySelector('#accept_analytic')
		publicity = config.querySelector('#accept_publicity')
		rst_publicity = config.querySelector('#accept_rst_publicity')

		analytic.checked = true
		publicity.checked = true
		rst_publicity.checked = true
		if ( response = cookieConfig.getCookie('CONSENT') ) {
			cookieResponse = response.split('|')

			analytic.checked = (cookieResponse[0]) ? cookieConfig.evaluateVal(cookieResponse[0].split('=')) : false
			publicity.checked = (cookieResponse[1]) ? cookieConfig.evaluateVal(cookieResponse[1].split('=')) : false
			rst_publicity.checked = (cookieResponse[2]) ? cookieConfig.evaluateVal(cookieResponse[2].split('=')) : false
			//console.log('Load: ga ' + analytic.checked + ' | tp ' + publicity.checked + ' | rp ' + rst_publicity.checked)
		}
	},

	openConfig: function() {
		config = document.querySelector('.cookie-config')

		cookieConfig.addClass(overlay, 'config-open')
		cookieConfig.addClass(config, 'active')
		cookieConfig.checkCookies(config)

		config.querySelector('.close-cookies').addEventListener('click', function () {
			if ( cookiesPolicy && cookieConfig.getCookie('CONSENT')) {
				cookieConfig.closeOverlay()
			}
			cookieConfig.removeClass(document.querySelector('.cookie-config'), 'active')
			cookieConfig.removeClass(overlay, 'config-open')	
		})

		document.getElementById('cookie-config').addEventListener('submit', function (event) {
			event.preventDefault()
			ga_val = config.querySelector('#accept_analytic').checked
			tp_val = config.querySelector('#accept_publicity').checked
			rp_val = config.querySelector('#accept_rst_publicity').checked
			cookieConfig.saveConfig(ga_val, tp_val, rp_val)
			cookieConfig.closeOverlay()
			cookieConfig.removeClass(document.querySelector('.cookie-config'), 'active')
			if (document.querySelector('.cookie-bar'))
				cookieConfig.closeBanner()
		})
	},

	saveConfig: function(ga_val, tp_val, rp_val) {
		const saveLog = 'ga='+ga_val+'|tp='+tp_val+'|rp='+rp_val
		cookieConfig.setCookie('CONSENT', saveLog, 365)

		if ( !ga_val )
			cookieConfig.deleteGA(['_ga', '_gcl_au', '_dc_gtm_UA-7713710-1', '_gid', '_gat_UA-7713710-1', '_gac_UA-7713710-1', 'vtstep'])

		if ( !tp_val )
			cookieConfig.deleteGA(['AFFICHE_W', 'Wousq', 'wousq_sess', '_xttrk', '_xttrk_ids', '_xttrk_all'])

		if( !rp_val )
			cookieConfig.deleteGA(['Conversion', 'IDE', 'ANID', 'DSID', 'FLC', 'AID', 'TAID', 'exchange_uid', 'AID', 'DSID', 'TAID', 'fr', 'oo', 'ddid', '_fbc', 'fbp', '_uetmsclkid', 'obuid', 't_gid'])

		console.log(saveLog)
		dataLayer.push ({
			'event': 'cmpconsentupdate',
			'cmpconsentupdate': saveLog
		})		
	},

	deleteGA: function(cookies) {
		cookies.forEach( element => cookieConfig.deleteCookie(element) )
	},

	getHostDomain: function() {
    if (document.URL.indexOf('localhost') >= 0) {
      return ".localhost"
		} else if (document.URL.indexOf('internetmasmovil') >= 0) {
			return ".internetmasmovil.com";
		} else if (document.URL.indexOf('simuladorhipotecas') >= 0) {
			return ".simuladorhipotecas.com";
		}
	},

	getWlParameters: function() {
    if ( document.URL.indexOf('utm_medium=iframe') >= 0 && document.URL.indexOf('utm_campaign=whitelabels') >= 0 )
      return true
	},

	bannerTpl: function () {
		const ctpl = `<div id="cookies" class="cookie-bar open">
									 <div class="container flex-container">
											 <div class="col-xs-12 col-sm-7 col-md-8 text">
											 <p>Utilizamos cookies propias y de terceros para personalizar tu experiencia y la publicidad que verás en otros sitios. Haciendo click en aceptar, aceptas todas las coookies. En configurar puedes entender más acerca de cada tipo de cookies y decidir cuáles aceptas.</p>
										 </div>
										 <div class="col-xs-12 col-sm-5 col-md-4 buttons">
										 	 <button class="btn solid-main config-btn">
										 		 <span>Configurar</span>
									 		 </button>
										 	 <button class="btn solid-main accept-btn">
										 		 <span>Aceptar</span>
									 		 </button>
										 </div>
									 </div>
								 </div>`
		return ctpl
	}, 

	configTpl: function () {
		const btpl = `<div class="cookie-config">
		  					 	<span class="close-cookies"></span>
		  							<div class="container-fluid">
		    							<div class="row">
		      							<p class="details-title">A continuación tienes un detalle de las cookies que utilizamos y si quieres más información, puedes consultar la Información de cookies.</p>
												<hr>
												<form role="form" name="cookie-config" method="get" id="cookie-config" aria-label="Configuración Cookies" enctype="multipart/form-data">
												  <div class="form-control normal-checkbox white">
												    <label class="normal-checkbox-container">
												      <input type="checkbox" name="accept_technical" id="accept_technical" checked disabled>
												      <div class="checkmark-container">
												        <span class="checkmark disabled"></span>
												        <span class="text"><strong>Cookies técnicas (necesarias):</strong> Son el tipo más básico, que hacen posible la navegación a través de nuestra página web y la utilización de las diferentes opciones o servicios que esta página incluye. Es decir, sirven para las tareas básicas de funcionamiento de la web, por lo que no requieren consentimiento.</span>
												      </div>
												    </label>
												  </div>
													<hr>
													<div class="form-control normal-checkbox white">
														<label class="normal-checkbox-container">
															<input type="checkbox" name="accept_analytic" id="accept_analytic">
															<div class="checkmark-container">
																<span class="checkmark"></span>
																<span class="text"><strong>Cookies de Análisis:</strong> Recogen información anónima de la navegación que estás realizando, como la franja horaria, navegador, duración de la visita, etc., que utilizamos para mejorar tu experiencia. Aunque utilizamos los servicios de Google Analytics para almacenar esta información temporalmente en tu dispositivo, Rastreator es el único capaz de utilizar esta información.</span>
															</div>
														</label>
													</div>
													<hr>
													<div class="form-control normal-checkbox white">
														<label class="normal-checkbox-container">
															<input type="checkbox" name="accept_publicity" id="accept_publicity">
															<div class="checkmark-container">
																<span class="checkmark"></span>
																<span class="text"><strong>Cookies de publicidad personalizada de terceros:</strong> Permiten compartir información sobre tus intereses con terceros con los que colaboramos, para que éstos puedan mostrarte publicidad personalizada o adaptada a tus intereses, en otras páginas en las que navegues. La información que se comparte con estos terceros no serán datos que permitan identificarte personalmente, pero sí  identificar tu navegador y tu dispositivo. Si no permites estas cookies estos sitios te mostrarán publicidad menos personalizada.</span>
															</div>
														</label>
													</div>
													<hr>
													<div class="form-control normal-checkbox white">
														<label class="normal-checkbox-container">
															<input type="checkbox" name="accept_rst_publicity" id="accept_rst_publicity">
															<div class="checkmark-container">
																<span class="checkmark"></span>
																<span class="text"><strong>Cookies de publicidad personalizada:</strong> Permiten recopilar información sobre tus intereses para mostrarte publicidad personalizada o adaptada a tus intereses, en otras páginas en las que navegues. Esta información no se comparte con terceros. Si no permites estas cookies los terceros sitios te mostrarán publicidad menos personalizada.</span>
															</div>
														</label>
													</div>
												  <div class="last">
												    <button class="btn solid-main" type="submit">
												      <span>Guardar</span>
												    </button>
												  </div>
												</form>
											</div>
		  							</div>
									</div>`
		return btpl
	},

	getCookie: function(cname) {
    var name = cname + "=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(';');
    for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == ' ') {
          c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
          return c.substring(name.length, c.length);
        }
    }
    return "";
	},

	setCookie: function(cname, cvalue, exdays) {
    var d = new Date()
    d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000))
    var expires = "expires=" + d.toUTCString()
    document.cookie = cname + "=" + cvalue + ";" + expires + ";domain=" + cookieConfig.getHostDomain() + ";path=/"
	},

	deleteCookie: function(cname) {
    document.cookie = cname + "=;expires=Thu, 01 Jan 1970 00:00:01 GMT;domain=" + cookieConfig.getHostDomain() + ";path=/"
	},

	evaluateVal: function(value) {
		return ( value[1] && value[1] === 'true'  ) ? true : false
	},

	addClass: function(el, className) {
    el.length || addClass(el, className)
    el.length > 1 && Array.from(el).forEach(e => addClass(e, className))
    function addClass(el, className) {
      if (el.classList) {
        el.classList.add(className)
      } else {
        el.className += ' ' + className
      }
    }
  },

	removeClass: function(el, className) {
    el.length || removeClass(el, className)
    el.length > 1 && Array.from(el).forEach(e => removeClass(e, className))
    function removeClass(el, className) {
      if (el.classList) {
        el.classList.remove(className)
      } else {
        el.className = el.className.replace(new RegExp('(^|\\b)' + className.split(' ').join('|') + '(\\b|$)', 'gi'), ' ')
      }
    }
  },
}
cookieConfig.init()